@import './../../styles/base/variables.scss';
/* @import url('https://webfontworld.github.io/pretendard/Pretendard.css'); */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');

:root {
  color-scheme: light only;
}

html {
  font-family: 'Pretendard', 'sans-serif';
  font-size: 62.5%;
  margin: 0 auto;
  overflow-x: hidden;
}

body {
  background: #fff !important;
}

//모든 이미지 드래그 막기
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  //IOS 저장막기
  -webkit-touch-callout: none;
  //AOS 저장막기
  pointer-events: none;
}

.pointer-event-true {
  pointer-events: auto !important;
}

//이미지 event적용은 따로 class설정하기

@media all and (max-width: 1300px) {
  .content-inner {
    max-width: none;
    padding: 0 2%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 50%;
    box-sizing: border-box;
  }
}

@media all and (max-width: 767px) {
  html {
    font-size: 40%;
    box-sizing: border-box;
  }
}

//
@media (prefers-color-scheme: dark) {
  html,
  body {
    background: #fff !important;
    background-color: #fff !important;
  }
}
