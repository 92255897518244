.landing-wrap {
  width: 100%;
  color: #333e4c;
  .swiper {
    width: 100%;
    height: 100%;
  }

  .landing-logo {
    max-width: 26%;
    padding-top: 1%;
  }
  .landing-footer-img {
    width: 36%;
    margin-top: 10%;
  }

  .swiper-slide {
    width: 100%;
    height: 100% !important;
  }
  .big-slide {
    padding: 4% 4%;
  }
  .small-slide {
    padding: 4% 20%;
  }

  .landing-box {
    width: 100%;
    height: 100%;
  }

  .landing-box-contents {
    z-index: 1;
  }

  .landing-box-img {
    img {
      width: 100%;
      display: block;
    }
  }
  .landing-title {
    color: #3f8afb;
    font-size: 3.6rem;
    line-height: 1.3;
    font-weight: bold;
  }
  .landing-title-small {
    color: #90bbfb;
    font-size: 2.4rem;
    padding: 1.6% 0 0.7% 0;
    font-weight: 400;
  }
  .landing-txt-box {
    padding-top: 10%;
  }
  .landing-txt-small {
    line-height: 1.8;
    font-size: 2rem;
  }
  .landing-txt-big {
    font-size: 2.8rem;
    font-weight: bold;
    padding-top: 10%;
  }

  .landing-start-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(#3f8afb, #a7f4a5);
    color: #fff;
    .landing-box-contents {
      margin: auto;
    }
    .landing-title {
      color: #fff;
    }
    .landing-title-small {
      display: block;
      color: #fff;
    }
    .landing-start-big-txt {
      font-size: 6.2rem;
      font-weight: bold;
      margin-top: 1.6%;
    }
    .landing-start-btn {
      display: block;
      cursor: pointer;
      z-index: 1;
      position: relative;
      margin: 1.6% auto 0 auto;
      width: 12%;
      img {
        width: 100%;
      }
    }
    .landing-box-img {
      position: relative;
      bottom: 10%;
      img {
        width: 84%;
        margin: auto;
      }
    }
  }
  .landing-influencer-box {
    background: #e1f3ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .landing-box-img {
      max-width: 41.5rem;
      width: 50%;
    }
    .landing-title-small {
      display: block;
      font-weight: bold;
      padding-bottom: 1.6%;
    }
  }
  .landing-global-box {
    background: #e1f3ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    text-align: right;
    .landing-box-img {
      display: flex;
      justify-content: center;
      max-width: 52.5rem;
      width: 50%;
    }
    .landing-title-small {
      display: block;
      font-weight: bold;
      padding-bottom: 1.6%;
    }
  }
  .landing-coming-box {
    background: linear-gradient(#c4e3ff, #cbf4ca);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .landing-box-img {
      max-width: 26.6rem;
      width: 56%;
      margin: auto;
    }
    .landing-txt-box {
      padding: 2% 0 10% 0;
    }
    .landing-txt-small {
      font-size: 2.4rem;
      line-height: 1.3;
    }
    .star-bg-img {
      width: 60%;
      bottom: 20%;
      z-index: -1;
    }
  }

  .gradation-img {
    position: absolute;
    bottom: -14%;
    width: 66%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media all and (max-width: 1700px) {
  .landing-wrap {
    .small-slide {
      padding: 4% 10%;
    }
  }
}

@media all and (max-width: 1200px) {
  .landing-wrap {
    .small-slide {
      padding: 4%;
    }
  }
}

@media all and (max-width: 890px) {
  .landing-wrap {
    .landing-start-box {
      .landing-start-btn {
        padding: 4% 0;
      }
    }

    .landing-coming-box {
      .star-bg-img {
        width: 70%;
        bottom: 34%;
      }
    }

    .gradation-img {
      bottom: 0;
    }
  }
}

@media all and (max-width: 660px) {
  .landing-wrap {
    .small-slide {
      padding: 0%;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .landing-box-contents {
      padding-top: 4%;
    }

    .gradation-img {
      width: 100%;
    }

    .landing-start-box {
      .landing-box-img img {
        width: 100%;
      }
      .landing-start-btn {
        width: 40%;
      }
    }
    .landing-coming-box {
      .landing-box-img {
        width: 40%;
      }

      .star-bg-img {
        width: 80%;
        bottom: 40%;
      }
    }
  }
}

@media all and (max-height: 900px) {
  .landing-wrap {
    .landing-box-img {
      img {
        max-height: 33vh;
        width: auto;
        margin: auto;
      }
    }
  }
  .landing-coming-box .star-bg-img {
    width: auto;
    height: 38vh;
  }
}
@media all and (max-height: 400px) {
  .landing-wrap {
    .landing-logo {
      width: 13%;
    }
    .landing-footer-img {
      width: 24%;
      margin-top: 6%;
    }
    .big-slide {
      padding: 2% 4%;
    }
    .landing-start-box {
      .landing-start-big-txt {
        font-size: 4rem;
      }
      .landing-start-btn {
        width: 30%;
      }
      .landing-box-img img {
        width: auto !important;
      }
    }
    .landing-coming-box .landing-txt-box {
      padding: 2% 0 6% 0;
    }

    .gradation-img {
      bottom: -14%;
    }
  }
}
